import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import Tokens from "../components/TokenMethods/TokenMethods";
import Dividends from "../components/Divedents/Divedents";
import Docs from "../components/Docs/Docs";
import Header from "../components/Header/Header";

export const Rout = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tokens" element={<Tokens />} />
        <Route path="/dividends" element={<Dividends />} />
        <Route path="/docs" element={<Docs />} />
      </Routes>
    </Router>
  );
};
