import React, { useState } from "react";
import styles from "./Docs.module.scss";

const Sidebar = ({ isWideScreen, setIsModelOpen, topics, onTopicSelected, onChapterSelected }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSubtopic, setActiveSubtopic] = useState(null);

  const handleChapterClick = (index, chapter) => {
    setActiveIndex(index);
    onChapterSelected(chapter);
    onTopicSelected(topics[chapter][0]);
    setActiveSubtopic(topics[chapter][0]);
    // setIsModelOpen(false);
  };

  const handleSubtopicClick = (subtopic) => {
    setActiveSubtopic(subtopic);
    onTopicSelected(subtopic);
    if (!isWideScreen) {
      setIsModelOpen(false);
    }
  };

  return (
    <div className={styles.sidebar}>
      {Object.entries(topics).map(([chapter, subtopics], index) => (
        <div key={index}>
          <div
            className={
              activeIndex === index ? `${styles.chapter} ${styles.active}` : styles.chapter
            }
            onClick={() => handleChapterClick(index, chapter)}
          >
            {chapter}
          </div>
          {activeIndex === index && (
            <ul>
              {subtopics.map((subtopic, subIndex) => (
                <li key={subIndex} className={styles.topic}>
                  <a
                    href={`#${subtopic.replace(/\s+/g, "")}`}
                    className={activeSubtopic === subtopic ? styles.active : ""}
                    onClick={() => handleSubtopicClick(subtopic)}
                  >
                    {subtopic}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
