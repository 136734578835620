export const featuresData = [
  {
    img: "/safety.png",
    title: "Safety",
    description:
      "Wallet keys are generated on the user's device and are not transferred or stored externally. All data is encrypted and stored exclusively in local storage, which minimizes risks in the event of cyber attacks.",
  },
  {
    img: "/privacy.png",
    title: "Privacy",
    description:
      "We do not collect any personal data from users other than their public wallet addresses. This guarantees a high level of anonymity.",
  },
  {
    img: "/multicurrency.png",
    title: "Multicurrency",
    description:
      "Supports various types of tokens and NFTs. Users can manage a variety of assets through a single interface.",
  },
  {
    img: "/staking.png",
    title: "Staking",
    description:
      "Users can earn money by staking tokens, receiving income in the form of additional coins for participating in maintaining the network.",
  },
  {
    img: "/card.png",
    title: "Card",
    description:
      "Possibility of ordering a cryptocurrency card for payments in everyday life and receiving cashback for transactions.",
  },
  {
    img: "/exchange-and-trade.png",
    title: "Exchange and trade",
    description:
      "Built-in exchange functionality allows users to buy, sell and exchange cryptocurrencies at favorable rates. Supports fiat currency exchange using over 130 local payment methods.",
  },
  // {
  //   img: "/thornLogo.png",
  //   title: " PRICKLY THORN '0xe1c5cd1efea20acf59b089ae2610d535f8ec589a'",
  //   description:
  //     "A unique token is a dividend token on the Polygon network, which gives you the right to receive a reward for storing the token on any of your wallets.",
  // },
];
