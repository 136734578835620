import React from "react";
import styles from "../../Docs.module.scss";

const Reward = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Reward</h1>
      <p className={styles.text}>
        Thorn's reward mechanism is an innovative system designed to encourage participation and
        increase the utility of the token Thorn in the ecosystem. This mechanism is based on
        distribution rewards among token holders and participants, encouraging them to actively
        interacting online and maintaining their investment. Below key aspects of accumulation and
        distribution of rewards are described.
      </p>

      <p className={styles.textChapter}>Transaction fees</p>
      <p className={styles.text}>
        A commission of 0.001% is accepted on each transaction, which automatically transferred to a
        special reward pool. This pool bring rewards to all token holders.
      </p>
      <p className={styles.textH}>Token unfreezing mechanism</p>
      <p className={styles.text}>
        For each transaction, 0.1% of the transaction amount is unfrozen, an additional share of
        tokens goes into the reward pool. This increases the number of tokens available for
        distribution among holders.
      </p>

      <p className={styles.textChapter}>Reward distribution</p>

      <p className={styles.text}>
        Pool rewards vary between all token holders Increase their share of the total supply of
        Thorn tokens in searches. This incentivizes save holders and maintains their investment in
        Thorne tokens
      </p>
      <p className={styles.textChapter}>Regular payments</p>
      <p className={styles.text}>
        Rewards are paid regularly (with each new entry into pool, the holder can take back his
        share), providing holders a consistent stream of income based on their participation in the
        ecosystem.
      </p>
      <p className={styles.text}>
        Thorn's reward mechanism is a key element of the economic token model aimed at creating
        incentives to increase volume transactions, increasing user engagement and maintaining value
        token in the long term.
      </p>
    </div>
  );
};

export default Reward;
