import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

const TokenMethods = () => {
  const [tokenInfo, setTokenInfo] = useState(null);

  const fetchTokenInfo = useCallback(async () => {
    try {
      const response = await axios.get("https://cactus-web.onrender.com/info");
      setTokenInfo(response.data.response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchTokenInfo();
  }, [fetchTokenInfo]);

  return (
    <div>
      <h1>Token Information</h1>
      {tokenInfo && (
        <div>
          <p>Symbol: {tokenInfo.symbolThorn}</p>
          <p>Name: {tokenInfo.name}</p>
          <p>Initial Supply: {tokenInfo.INITIAL_SUPPLY}</p>
          <p>Max Supply: {tokenInfo.maxSupply}</p>
          <p>Frozen Supply: {tokenInfo.frozenSupply}</p>
          <p>Reward Pool: {tokenInfo.rewardPool}</p>
          <p>Total Commission: {tokenInfo.totalCommission}</p>
          <p>Total Unfrozen: {tokenInfo.totalUnfrozen}</p>
          <p>Transaction Count: {tokenInfo.transactionCount}</p>
          <p>Holder Count: {tokenInfo.holderCount}</p>
          <p>Total Transaction Amount: {tokenInfo.totalTransactionAmount}</p>
          <p>Circulating Supply: {tokenInfo.circulatingSupply}</p>
        </div>
      )}
    </div>
  );
};

export default TokenMethods;
