import React from "react";
import { NavLink } from "react-router-dom";

import style from "./Header.module.scss";

const Logo = () => {
  return (
    <>
      <NavLink className={style.box} to="/">
        <img className={style.logo} src="/Logo.svg" alt="Logo" />
        <p className={style.logoText}>Cactus</p>
      </NavLink>
    </>
  );
};

export default Logo;
