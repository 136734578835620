import React from "react";
import styles from "../../Docs.module.scss";

const Tools = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <p className={styles.text}>
        "Prickly Wallet" offers users a variety of financial tools to manage and increase their
        cryptocurrency assets. Each of these functions generates income for the company with each
        operations and these profits are used to drive growth and liquidity of the Thorn token.
      </p>
      <p className={styles.textChapter}>- Crypto Staking</p>
      <p className={styles.textChapter}>- Crypto Swap</p>
      <p className={styles.textChapter}>- Withdrawal to Fiat</p>
      <p className={styles.text}>
        The main profit from all the above operations is used for regular repurchase of Thorn tokens
        at market price every quarter. Is not only maintains constant demand for the token, but also
        reduces it circulating supply, which potentially leads to an increase in its value. This
        model stimulates long-term growth and liquidity of the token, providing benefits for both
        token holders and the "Prickly Wallet" ecosystem.
      </p>
    </div>
  );
};

export default Tools;
