import React from "react";

const Square = ({ title, value }) => (
  <div style={squareStyle}>
    <p>{title}</p>
    <p>{value}</p>
  </div>
);

const squareStyle = {
  width: "150px",
  height: "150px",
  backgroundColor: "rgba(18, 12, 20, 0.60)",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  margin: "10px",
  flexShrink: 0,
  border: "1px solid var(--GOOD-GREEN, #34AD8A)",
};

export default Square;
