import React from "react";
import styles from "../../Docs.module.scss";

const Cactus = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <p className={styles.textChapter}>
        Welcome to the Wild West of cryptocurrencies, where everyone listens to your steps to hear
        the jingle of coins, count them, and perhaps try to take your gold. But just as every coyote
        avoids the prickly cactus, our community has built the most reliable city of cactuses so you
        can safely store your coins.
      </p>
      <p className={styles.textChapter}>
        In this documentation, i am pleased to tell you how the Cactus universe works and how the
        Prickly Wallet and Prickly Thorn interact within it. You will become acquainted with every
        corner of our system and learn how these elements synergistically combine to provide
        security, convenience, and profitability to every member of our family.
      </p>
    </div>
  );
};

export default Cactus;
