import { useState } from "react";
import Logo from "../../Header/Logo";
import style from "./Hero.module.scss";
import BurgerMenu from "./BurgerMenu";
import { BurgerButton } from "../../BurgerButton/BurgerButton";

const Hero = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const closeMenu = () => {
    setIsModelOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <section className={style.heroSection}>
        <div className={style.heroContainer}>
          <div className={style.mobileHeader}>
            <Logo />

            <BurgerButton setIsModelOpen={setIsModelOpen} />
          </div>

          <div className={style.blockTextH}>
            <p className={style.textH}>Cryptocurrency wallet</p>
            <p className={style.textH}>
              <span className={style.textPrickly}>"Prickly Wallet" </span>
              from
            </p>
          </div>

          <div className={style.nameWrapper}>
            <h1 className={style.nameHeroTitle}>
              CAC
              <span className={style.cuctusImgContainer}>
                <img className={style.cactus} src="/cactus.svg" alt="cactus" loading="lazy" />
              </span>
              US
            </h1>
          </div>

          <h3 className={style.decriptionText}>
            Provides high security and full control over funds, allowing you to conveniently manage
            crypto assets.
          </h3>
          <img className={style.heroIcon} src="/Head.svg" alt="cactus" />
        </div>
      </section>
      {isModelOpen && <BurgerMenu closeMenu={closeMenu} />}
    </>
  );
};

export default Hero;
