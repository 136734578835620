import React from "react";
import styles from "../../Docs.module.scss";

const Swap = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Crypto Swap</h1>
      <p className={styles.text}>
        Swap allows users to quickly and easily exchange one cryptocurrency to another right inside
        the wallet. This process makes it easier management of a cryptocurrency portfolio without
        the need to turn to external exchanges.
      </p>
      <p className={styles.text}>
        The user selects a pair of cryptocurrencies for exchange, indicates the quantity, who wants
        to exchange, and confirms the transaction. "Prickly Wallet" automatically calculates the
        corresponding exchange rate and commission for exchange. Once confirmed by the user, the
        transaction is processed internally systems, and exchange cryptocurrencies are credited to
        his account.
      </p>
      <p className={styles.text}>
        Swap feature increases liquidity within the platform and increases volume transactions,
        which contributes to the growth of the company’s commission income. This also improves the
        user experience, making "Prickly Wallet" more attractive to a wide audience of users.
      </p>
    </div>
  );
};

export default Swap;
