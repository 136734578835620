import style from "./BurgerButton.module.scss";

export const BurgerButton = ({ setIsModelOpen }) => {
  const openMenu = () => {
    setIsModelOpen(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <button className={style.btnBurgerMenu} onClick={openMenu}>
      <img src="/burger-menu.svg" alt="burger-menu" />
    </button>
  );
};
