import React from "react";
import styles from "../../Docs.module.scss";

const Staking = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Crypto Staking</h1>
      <p className={styles.text}>
        Cryptocurrency staking is the process of participating in blockchain network operations by
        locking a certain amount of cryptocurrency to support the network and its security.
        Participants, or "stakers," temporarily "freeze" their tokens to confirm transactions and
        create new blocks.
      </p>
      <p className={styles.text}>
        In "Prickly Wallet," users can choose from various cryptocurrencies for staking. After
        selecting a suitable cryptocurrency and amount, these funds are locked in the user's wallet
        for a specified period. In exchange for participating in staking, users receive rewards in
        the form of interest on their investments, which are paid out from transaction fees or
        blocks created by the network.
      </p>
      <p className={styles.text}>
        Staking enhances the security of the blockchain network and maintains it operational
        activities, since more participants are involved transaction verification. For "Prickly
        Wallet", this also creates a stable source of income through commissions collected from
        staking rewards, and promotes retention of users and their assets within the platform.
      </p>
    </div>
  );
};

export default Staking;
