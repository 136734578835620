import AnimatedListItem from "./AnimatedListItem";
import { featuresData } from "./featuresData";

import style from "./MainFeatures.module.scss";

const MainFeatures = () => {
  return (
    <section className={style.mainSection}>
      <div className={style.mainContainer}>
        <h2 className={style.mainTitle}>Main features and benefits:</h2>
        <ul className={style.listItems}>
          {featuresData.map(({ img, title, description }, i) => (
            <AnimatedListItem key={title} img={img} title={title} description={description} i={i} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default MainFeatures;
