import React from "react";
import styles from "../../Docs.module.scss";

const Motivation = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Motivation letter for shareholders</h1>
      <p className={styles.text}>
        In every era of human history, there has been a moment when fundamental changes in financial
        systems and currency relations have opened new horizons of opportunities and challenges.
        Recall how the transition from the barter system to the use of shells, then to gold coins,
        and subsequently to paper money and credit cards, played a key role in shaping the economies
        of nations, contributing to their prosperity or, conversely, their decline. These moments of
        transition did not just change the ways of exchanging value; they rewrote the rules of the
        economic game, determined the fates of empires, and laid the groundwork for new world
        orders.
      </p>
      <p className={styles.text}>
        We observe how great civilizations of the past, such as the Roman Empire, reached their apex
        thanks to innovations in the financial system, and then suffered defeat when their currency
        systems collapsed under the burden of inflation caused by an excessive increase in the money
        supply without corresponding economic coverage. These historical lessons remind us of the
        critical role of stability and trust in the economic system.
      </p>
      <p className={styles.text}>
        Today, as we experience another historic turn with the advent of cryptocurrencies, we see
        not just a technological innovation, but also the opportunity for fundamental changes in
        relationships among people, states, and economies. Cryptocurrencies offer a solution to many
        problems faced by our ancestors, providing reliable, transparent, and accessible means for
        storing and exchanging values. They offer us the chance to create a new world order in which
        every individual has control over their finances, is free from the instability of
        traditional currencies, and is protected from inflation. We now see not only a change in the
        means themselves but also a striving for a fairer and more accessible financial system.
      </p>
      <p className={styles.text}>
        Changes in the global economic arena, such as the transition from the gold standard to fiat
        currencies, and the emergence of credit cards, paved the way to the era of digital finance,
        where every person has the right to control their funds without intermediaries and hidden
        fees. This reminds us of times when economic systems underwent radical changes in response
        to societal and technological challenges, pushing nations toward prosperity or crises
        depending on their flexibility and innovative activity.
      </p>
      <p className={styles.text}>
        At Cactus, we draw inspiration from these historical lessons and strive to create a product
        that not only improves financial accessibility and security for people worldwide but also
        gives everyone the opportunity to be a full participant in the global economy. Our
        cryptocurrency wallet, Prickly Wallet, is not just a technological solution for secure
        storage and management of digital assets. It is our response to the challenges of the times,
        an effort to make the world more open and fair for everyone.
      </p>
      <p className={styles.text}>
        We envision a future where financial barriers between countries and continents disappear,
        where every person, regardless of where they live, will have equal access to global
        resources. In this future, your financial freedom and security are our priority.
      </p>
      <p className={styles.text}>
        By joining our Cactus family, you become part of not only an innovative project but also a
        global movement towards a better future. Together, we are creating a new chapter in human
        history, where financial relations are built on trust, transparency, and respect for each
        participant.
      </p>
      <p className={styles.text}>
        Join us on this journey. Together, we can achieve more. With respect and hope for a shared
        future, Your Cactus family.
      </p>
      <p className={styles.textSing}>Cactus</p>
    </div>
  );
};

export default Motivation;
