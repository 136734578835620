import React from "react";
import styles from "../../Docs.module.scss";

const PublicKey = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Public Key</h1>
      <p className={styles.text}>
        The public key is an important component of asymmetric cryptography, which is used to secure
        and manage transactions on the blockchain. Understanding the public key will help you better
        comprehend how cryptocurrency wallets and transactions work.
      </p>
      <p className={styles.text}>
        The public key is a cryptographic code that allows other users to send you cryptocurrency.
        It is generated in pair with the private key from the same initial number (often called a
        "secret key" or "seed") using an asymmetric encryption algorithm.
      </p>
      <h2 className={styles.textChapter}>How is the public key formed?</h2>
      <p className={styles.text}>
        It all starts with the creation of a private key, which is a randomly generated long number.
        This number must remain secret, as it is used to sign transactions and ensure access to your
        funds.
      </p>
      <p className={styles.text}>
        The public key is generated from the private key using a cryptographic algorithm (such as
        ECDSA in the case of Bitcoin). This process involves complex mathematical operations that
        ensure the public key can be easily created from the private key, but the reverse
        process—calculating the private key from the public key—is practically impossible.
      </p>

      <p className={styles.text}>
        Your public key is used as an address (or part of an address) to which other users can send
        you cryptocurrency.
      </p>
      <p className={styles.textChapter}>The Role of the Public Key in Transactions</p>
      <p className={styles.text}>
        When you send cryptocurrency, you use your private key to sign the transaction. Anyone in
        the network can use your public key to verify that the signature is valid and that the
        transaction indeed originates from you. This ensures the security and transparency of
        transactions without the need to disclose your private key.
      </p>
      <p className={styles.text}>
        The public key is inherently meant to be distributed and does not require the same level of
        precaution as the private key. However, it is important to ensure that your private key
        never becomes known to outsiders, as this would lead to a loss of control over your funds.
      </p>
      <p className={styles.text}>
        When you set up a cryptocurrency wallet, you are provided with a mnemonic phrase, which is
        used to generate all associated private keys (and corresponding public keys). Each time you
        or someone else uses your public key to send funds to your wallet, the corresponding
        cryptocurrency will be correctly credited and accessible through your private key. Thus, the
        public key is an integral part of the cryptocurrency ecosystem, providing a convenient and
        secure way to conduct transactions.
      </p>
    </div>
  );
};

export default PublicKey;
