import { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

import style from "./MainFeatures.module.scss";

const AnimatedListItem = ({ img, title, description, i }) => {
  const [isVisible, setIsVisible] = useState(false);

  const isOdd = i % 2 === 0;
  const startX = isOdd ? "-50px" : "50px";

  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateX(0)" : `translateX(${startX})`,
    delay: 300
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const listItem = document.getElementById(title);

      if (listItem) {
        const listItemOffset = listItem.offsetTop;
        currentScrollY > listItemOffset - window.innerHeight + 200
          ? setIsVisible(true)
          : setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [title]);

  return (
    <animated.li id={title} className={style.itemBlock} style={props}>
      <img src={img} alt={title} className={style.blockImg} loading="lazy" />
      <div className={style.itemTextWrapper}>
        <h3 className={style.blockTitle}>{title}</h3>
        <p className={style.blockDescription}>{description}</p>
      </div>
    </animated.li>
  );
};

export default AnimatedListItem;
