import React from "react";
import styles from "../../Docs.module.scss";

const mnemonicPhrase = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textChapter}>Мнемоническая фраза</h1>
      <p className={styles.text}>
        Interaction with the wallet begins with a mnemonic phrase—a set of 12, 18, or 24 words
        generated by the wallet during the initial setup. These words are formed based on a random
        number and comply with specific encoding rules that are used to create the wallet's
        cryptographic secret key.
      </p>
      <p className={styles.textChapter}>How does it work? </p>
      <p className={styles.text}>
        The process of generating a mnemonic phrase is based on the BIP39 standard (Bitcoin
        Improvement Proposal 39), which is a method of transforming a random number into a set of
        words. The process is as follows:
      </p>
      <p className={styles.text}>
        Random Number Generation: Firstly, the wallet generates a random number, which typically has
        a length of 128, 160, 192, 224, or 256 bits. The larger the size of the number, the higher
        the level of security provided.
      </p>
      <p className={styles.text}>
        Creating a Checksum: From this number, a checksum is created to provide additional error
        checking. The checksum is generated by applying the SHA-256 hash function to the random
        number and adding the first few bits of this hash (for example, 4 bits for a 128-bit number)
        to the original number.
      </p>
      <p className={styles.text}>
        Example: Imagine that your random number, after adding the checksum, results in a sequence
        of bits that is divided into segments. Each segment corresponds to a word number in a
        dictionary. Thus, the sequence 01100000001 might correspond to the word "apple," 10000000011
        to the word "orange," and so forth until the complete mnemonic phrase is formed.
      </p>
      <p className={styles.text}>
        Conversion to Words: The resulting number (random number plus checksum) is divided into
        segments of 11 bits each. Each of these segments encodes a number from 0 to 2047,
        corresponding to one word from a special dictionary that contains 2048 words.
      </p>
      <p className={styles.text}>
        GPT The user must write down the mnemonic phrase and store it in a safe place. This is the
        only way to restore access to the wallet if the device is lost or damaged.
      </p>
      <p className={styles.text}>
        To restore the wallet on a new device, the user enters the mnemonic phrase. The system uses
        these words to generate all the private keys associated with the wallet and restores access
        to the funds.
      </p>
      <p className={styles.text}>
        The mnemonic phrase provides a simple yet powerful method for wallet recovery without the
        need to copy and store complex files or data. It also enhances the resilience of the
        cryptocurrency account to attacks, as access to funds can be restored on another device even
        in the event of a complete loss of the original device.
      </p>
      <p className={styles.text}>
        No one should disclose their mnemonic phrase, as anyone who obtains it will be able to
        access the wallet and the funds.
      </p>
      <p className={styles.text}>
        It is best to store the mnemonic phrase in physical form (for example, on paper) in a
        reliable and secure place. The use of safes or other secure storage is recommended to
        enhance security.
      </p>
    </div>
  );
};

export default mnemonicPhrase;
