import React from "react";
import { Rout as Router } from "./navigation/Router"; // Изменен импорт согласно именованному экспорту

function App() {
  return (
    <div className="fullScreen">
      <Router />
    </div>
  );
}

export default App;
