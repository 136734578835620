import React from "react";
import styles from "../../Docs.module.scss";

const PrivateKey = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textChapter}>Private Key</h1>

      <p className={styles.text}>
        The private key is a unique secret number used to generate signatures for transactions,
        allowing the wallet owner to send cryptocurrency. It is also used to generate the
        corresponding public key. The private key must always remain confidential, as its disclosure
        can lead to loss of control over the funds at the associated address.
      </p>
      <h3 className={styles.textChapter}> How is the private key formed?</h3>

      <p className={styles.text}>
        The process begins with the creation of a random number, which becomes the private key. This
        number must be generated using a cryptographically secure random number generator. The
        random number is then converted into a format suitable for use in cryptographic encryption
        algorithms. In systems using ECDSA, this number is simply checked for compliance with
        cryptographic requirements (for example, it must be in the range from 1 to n-1, where n is
        the order of the point on the elliptic curve).
      </p>
      <h5 className={styles.textChapter}>Using the Private Key</h5>
      <p className={styles.text}>
        When sending a transaction, the private key is used to create a digital signature, which
        serves as proof that the wallet owner has authorized the transaction.
      </p>
      <p className={styles.text}>
        The private key is also used to generate the public key. This is done by applying an
        elliptic curve to the number representing the private key, in order to obtain a point on the
        curve, which then becomes the public key.
      </p>
      <h6 className={styles.textChapter}>Precautionary Measures</h6>
      <p className={styles.text}>
        Private keys should be stored in encrypted form and should never be disclosed to third
        parties.
      </p>
      <p className={styles.text}>
        It is important to create backups of private keys to avoid losing access to funds due to
        device failures.
      </p>
      <p className={styles.text}>
        Use mnemonic phrases to securely restore private keys on new devices.
      </p>
      <p className={styles.text}>
        The private key is a critically important element of cryptographic security in blockchain
        technologies. Proper management and storage of the private key is key to protecting your
        cryptocurrency assets.
      </p>
    </div>
  );
};

export default PrivateKey;
