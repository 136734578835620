import React from "react";
import styles from "../../Docs.module.scss";

const Interaction = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textChapter}>Interaction with a crypto wallet</h1>
      <p className={styles.text}>
        A cryptocurrency wallet like "Prickly Wallet" from Cactus provides users with a wide range
        of opportunities for interacting with the world of digital assets. It serves not only as a
        place to store cryptocurrencies but also as a versatile tool for participating in various
        aspects of the blockchain ecosystem.
      </p>
      <h1 className={styles.textChapter}>Registration and setup of the wallet</h1>
      <p className={styles.text}>
        The user begins by downloading and installing the wallet on their device.
      </p>
      <p className={styles.text}>
        Upon first launching the wallet, the user is prompted to create a new wallet, which includes
        generating a mnemonic phrase. This phrase must be securely saved, as it allows for the
        recovery of access to the wallet and its funds.
      </p>
      <p className={styles.textChapter}>Security</p>
      <p className={styles.text}>
        Setting a password and activating additional security measures, such as two-factor
        authentication, ensure the protection of the account.
      </p>
      <h2 className={styles.textChapter}>Funding and managing assets</h2>
      <p className={styles.text}>
        Users can easily receive and send cryptocurrencies using the public address of the wallet.
        This address can be provided to others for transferring funds.
      </p>
      <p className={styles.text}>
        To send cryptocurrency, the user enters the recipient's address, the amount, and confirms
        the transaction, which is secured by their private key.
      </p>
      <h3 className={styles.textChapter}>Interaction with tokens and NFTs</h3>
      <p className={styles.text}>
        Users can manage various types of tokens (such as ERC-20), which includes receiving,
        sending, and exchanging tokens.
      </p>
      <p className={styles.text}>
        The wallet provides access to platforms for buying, selling, and managing NFTs. This allows
        users to participate in the economy of digital art and collectibles.
      </p>
      <p className={styles.textChapter}>Participation in DeFi and voting</p>
      <p className={styles.text}>
        The wallet enables users to participate in DeFi (Decentralized Finance) applications, such
        as lending, staking, and earning interest on their investments.
      </p>
      <p className={styles.textChapter}>Voting in blockchain networks</p>
      <p className={styles.text}>
        Token holders can participate in voting on proposals in the blockchain network, using their
        tokens to influence the development of the platform or project.
      </p>
      <p className={styles.textChapter}>Support for multiple currencies and conversion to fiat </p>
      <p className={styles.text}>
        The wallet supports a multitude of cryptocurrencies and tokens, allowing the user to store,
        send, and receive various types of assets.
      </p>
      <p className={styles.text}>
        Users can convert cryptocurrency into fiat money and withdraw funds through various payment
        methods, including bank transfers and digital payment systems.
      </p>
      <p className={styles.textChapter}>Мобильный доступ и интеграция</p>
      <p className={styles.text}>
        Мобильные приложения кошелька позволяют пользователям управлять своими активами и проводить
        транзакции в любом месте и в любое время, предоставляя полный контроль над своими финансами.
      </p>
      <p className={styles.text}>
        В общем, криптовалютный кошелек дает пользователю мощные инструменты для управления
        цифровыми активами, участия в современной цифровой экономике и получения доступа к широкому
        спектру финансовых услуг в блокчейн-экосистеме.
      </p>
    </div>
  );
};

export default Interaction;
