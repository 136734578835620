import React from "react";
import styles from "../../Docs.module.scss";

const Fiat = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Withdrawal to Fiat</h1>
      <p className={styles.text}>
        GPT The fiat withdrawal feature allows users to convert their cryptocurrency assets into
        fiat money, facilitating easy access to their funds in the "real world."
      </p>
      <p className={styles.text}>
        Users select the amount of cryptocurrency they want to convert into fiat and choose a
        withdrawal method, such as to a bank account or through local payment systems. "Prickly
        Wallet" calculates the corresponding amount in fiat currency based on the current exchange
        rate and charges a fee for processing the transaction.
      </p>
      <p className={styles.text}>
        Fiat withdrawal enhances the functionality of the wallet, making it suitable not only for
        trading and investing but also for everyday use. This facilitates the attraction of new
        users who want to use cryptocurrency as an alternative means of payment, and increases
        transaction volumes, which directly impacts the company's revenues.
      </p>
    </div>
  );
};

export default Fiat;
