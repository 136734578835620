import React from "react";
import { NavLink } from "react-router-dom";

import Logo from "./Logo";
// import ConnectWallet from "./ConnectWallet";
import style from "./Header.module.scss";

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.wrapperHeader}>
        <Logo />
        <nav className={style.headerNav}>
          <ul className={style.listContainer}>
            {/* <li className={style.navLinkItem}>
              <NavLink className="text" to="/tokens">
                Tokens
              </NavLink>
            </li> */}
            <li className={style.navLinkItem}>
              <NavLink className="text" to="/">
                Wallet
              </NavLink>
            </li>
            <li className={style.navLinkItem}>
              <NavLink className="text" to="/dividends">
                Dividends
              </NavLink>
            </li>
            <li className={style.navLinkItem}>
              <NavLink className="text" to="/docs">
                Docs
              </NavLink>
            </li>
            {/* <li className={style.navLinkItem}>
              <ConnectWallet />
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
