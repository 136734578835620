import React from "react";
import Cactus from "./DocsText/Cactus/Cactus";
import PricklyWallet from "./DocsText/PricklyWallet/CryptoWalletBasics";
import Technological from "./DocsText/PricklyWallet/PrivateKey";
import PublicKey from "./DocsText/PricklyWallet/PublicKey";
import PrivateKey from "./DocsText/PricklyWallet/PrivateKey";
import MnemonicPhrase from "./DocsText/PricklyWallet/mnemonicPhrase";
import Interaction from "./DocsText/PricklyWallet/Interaction";
import Tokenomics from "./DocsText/Thorn/Tokenomics";
import Reward from "./DocsText/Thorn/Reward";
import Fiat from "./DocsText/Services/Fiat";
import Tools from "./DocsText/Services/Tools";
import Staking from "./DocsText/Services/Staking";
import Swap from "./DocsText/Services/Swap";
import Motivation from "./DocsText/Motivation/Motivation";

import styles from "../Docs/Docs.module.scss";

const MainContent = ({ topic }) => {
  const renderTopic = () => {
    switch (topic) {
      case "Introduction to Cactus":
        return <Cactus />;
      case "Crypto Wallet Basics":
        return <PricklyWallet />;
      case "Technological":
        return <Technological />;
      case "Mnemonic Phrase":
        return <MnemonicPhrase />;
      case "Public key":
        return <PublicKey />;
      case "Private Key":
        return <PrivateKey />;
      case "Interaction":
        return <Interaction />;
      case "Tokenomics":
        return <Tokenomics />;
      case "Reward":
        return <Reward />;

      case "Fiat Handling":
        return <Fiat />;
      case "Tools":
        return <Tools />;
      case "Staking Services":
        return <Staking />;
      case "Swap Operations":
        return <Swap />;
      case "Motivational Strategies":
        return <Motivation />;
      default:
        return (
          <div style={{ overflowY: "auto", height: "90vh" }}>
            <p className={styles.textChapter}>
              Welcome to a world of limitless possibilities with Cactus Wallet your reliable
              companion in the world of cryptocurrencies. In this documentation, we will explore in
              detail all aspects of our unique product, which not only provides secure storage for
              your assets but also opens new horizons for managing and increasing your capital. Here
              you will find all the necessary information about the functions of our cryptocurrency
              wallet, the unique features of our own coin, Thorn, and the multitude of tools that
              make our service truly outstanding.
            </p>
            <p className={styles.textChapter}>
              Join us to dive headlong into the world of digital finance, where every step towards
              greater financial independence is backed by cutting-edge security technologies and
              innovative solutions. Our goal is to make your experience with cryptocurrencies as
              comfortable and profitable as possible. Together, we can explore new opportunities and
              reach new heights in the world of cryptocurrencies. Welcome to the Cactus Wallet
              family!
            </p>
          </div>
        );
    }
  };

  return <div className={styles.mainContent}>{renderTopic()}</div>;
};

export default MainContent;
