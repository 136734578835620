import React, { useEffect, useState } from "react";
import MainContent from "./MainContent";
import Sidebar from "./Sidebar";
import styles from "./Docs.module.scss";
import { NavLink } from "react-router-dom";

const Docs = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const openMenu = () => {
    setIsModelOpen(true);
  };

  const topics = {
    Cactus: ["Introduction to Cactus"],
    PricklyWallet: [
      "Crypto Wallet Basics",
      "Mnemonic Phrase",
      "Public key",
      "Private Key",
      "Interaction"
    ],
    Thorn: ["Tokenomics", "Reward"],
    Services: ["Tools", "Fiat Handling", "Staking Services", "Swap Operations"],
    Motivation: ["Motivational Strategies"]
  };

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isWideScreen) {
      setIsModelOpen(true);
    }
  }, [isWideScreen]);

  return (
    <section className={styles.docsSection}>
      <div className={styles.docsContainer}>
        <div className={styles.docsButtonContainer}>
          <div className={styles.headerModal}>
            <button className={styles.btnDocBurgerMenu} onClick={openMenu}>
              <img src="/burger-menu.svg" alt="doc-burger-menu" />
            </button>
            {isModelOpen && (
              <NavLink className={styles.linkComeBack} to="/">
                Go to main
                <img src="./go-to-main.svg" alt="come-back" />
              </NavLink>
            )}
          </div>
          {isModelOpen && (
            <Sidebar
              topics={topics}
              setIsModelOpen={setIsModelOpen}
              onTopicSelected={setSelectedTopic}
              onChapterSelected={setCurrentChapter}
              isWideScreen={isWideScreen}
            />
          )}
        </div>

        <MainContent topic={selectedTopic} chapterTopics={topics[currentChapter] || []} />
      </div>
    </section>
  );
};

export default Docs;
