import { NavLink } from "react-router-dom";

import Logo from "../../Header/Logo";

import style from "./Hero.module.scss";

const BurgerMenu = ({ closeMenu }) => {
  return (
    <div className={style.backdrop}>
      <div className={style.modal}>
        <Logo />
        <nav className="burger-menu-nav">
          <ul className={style.listBurgerMenuNavLink}>
            <li>
              <NavLink className="text" to="/" onClick={closeMenu}>
                Wallet
              </NavLink>
            </li>
            <li>
              <NavLink className="text" to="/dividends" onClick={closeMenu}>
              Dividends
              </NavLink>
            </li>
            <li>
              <NavLink className="text" to="/docs" onClick={closeMenu}>
                Docs
              </NavLink>
            </li>
          </ul>
        </nav>
        <button>
          <img src="/close.svg" alt="close-menu" onClick={closeMenu} />
        </button>
      </div>
    </div>
  );
};

export default BurgerMenu;
