import React from "react";
import styles from "../../Docs.module.scss";

const PricklyWallet = () => {
  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <h1 className={styles.textH}>Crypto Wallet </h1>
      <p className={styles.text}>
        A cryptocurrency wallet is an application or software that allows users to store and manage
        their cryptocurrencies. The main function of such a wallet is the secure storage of
        cryptocurrencies and access keys to them, as well as conducting transactions using
        blockchain technologies. Here are a few key aspects to know about cryptocurrency wallets.
      </p>
      <p className={styles.textChapter}>Mnemonic phrase</p>
      <p className={styles.text}>
        A mnemonic phrase, also known as a seed phrase or secret phrase, plays a key role in the
        management and security of cryptocurrency wallets. It is one of the most important elements
        in the architecture of modern cryptocurrency wallets, providing the user with the ability to
        recover their wallet in case of losing access to the device or software.
      </p>
      <h2 className={styles.textChapter}>Private and public keys</h2>
      <p className={styles.text}>
        Cryptocurrency wallets use a pair of keys: a public key and a private key. The public key
        can be compared to a bank account number, which the user can share with others to receive
        payments. The private key is similar to a personal password, which should remain only with
        the user, as it provides access to their funds.
      </p>
      <h3 className={styles.textChapter}>Types of wallets</h3>
      <p className={styles.text}>
        Cold and hot wallets are the two main types of cryptocurrency wallets, each with its own
        advantages and disadvantages, as well as specific use cases. The main difference between
        these types lies in the level of internet access, which directly affects their security and
        ease of use.
      </p>
      <p className={styles.textChapter}>Cold Wallets</p>
      <p className={styles.text}>
        Cold wallets are a type of cryptocurrency wallet that are not connected to the internet.
        This can be a hardware device, a special USB flash drive, or even a paper document.
      </p>
      <p className={styles.text}> Advantages:</p>
      <p className={styles.text}>
        High security: Since the wallet is not connected to the internet, it is protected from
        online attacks, including phishing, hacking, and other cyber threats.
      </p>
      <p className={styles.text}>
        Long-term storage: Ideal for long-term storage of large amounts of cryptocurrency, as the
        risk of theft is minimal.
      </p>
      <p className={styles.text}>Disadvantages:</p>
      <p className={styles.text}>
        Inconvenience of use: Conducting transactions from a cold wallet requires additional steps,
        which can be less convenient than using hot wallets.
      </p>
      <p className={styles.text}>
        Risk of physical loss or damage: In the case of hardware or paper wallets, there is a risk
        of physical loss or damage.
      </p>
      <p className={styles.textChapter}>Hot Wallets</p>
      <p className={styles.text}>
        Hot wallets are always connected to the internet and can be implemented as mobile
        applications, desktop programs, or web services.
      </p>
      <p className={styles.text}>Advantages:</p>
      <p className={styles.text}>
        Ease of use: Hot wallets allow for quick and easy sending and receiving of cryptocurrencies,
        making them an ideal choice for frequent use.
      </p>
      <p className={styles.text}>
        Instant access: Users can carry out transactions at any time and from anywhere in the world
        where there is internet access.
      </p>
      <p className={styles.text}>Disadvantages:</p>
      <p className={styles.text}>
        Lower security level: Since hot wallets are constantly connected to the internet, they are
        more vulnerable to various types of cyberattacks.
      </p>
      <p className={styles.text}>
        In our application "Prickly Wallet," a cryptocurrency wallet is created with a high level of
        security and privacy. The wallet generation process is entirely conducted on the user's
        local device, eliminating any risks of data leakage to a server or the internet. Here's how
        this process works: When creating a new wallet, the application generates cryptographic keys
        directly on your device. This includes creating both private and public keys. The private
        key remains strictly confidential and never leaves your device. All sensitive data,
        including the mnemonic phrase and private keys, are encrypted on the device before being
        stored in local storage. This provides an additional layer of protection, making the data
        unreadable even if the device is compromised. The generated wallet and all associated data
        are stored exclusively on the device itself. No information is sent to external servers or
        to the cloud, which prevents third parties from accessing your data. All operations related
        to the creation and management of the wallet are conducted in an isolated environment on the
        user's device, minimizing the risks of attacks and data leaks. Since the application does
        not store or recover the mnemonic phrase, the full responsibility for its safekeeping lies
        with the user. If the mnemonic phrase is lost, it will be impossible to recover access to
        the wallet.
      </p>
      <p className={styles.text}>
        At this stage, we have provided an overview of what a cryptocurrency wallet entails. In the
        following sections, we will examine each component of the cryptocurrency wallet in detail so
        that you can thoroughly understand all its aspects and functions. We will focus on the
        registration process and daily operations with your personal cryptocurrency wallet, which
        will enable you to confidently manage your assets. Additionally, we will thoroughly discuss
        the security mechanisms that protect your funds and discuss how to properly use wallet
        features to enhance the efficiency of your transactions. You will learn about the importance
        of the mnemonic phrase for recovering access to your wallet and how to safely store and
        transfer cryptocurrency assets.
      </p>
    </div>
  );
};

export default PricklyWallet;
