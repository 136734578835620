import Hero from "./Hero/Hero";
import MainFeatures from "../MainFeatures/MainFeatures";

import style from "./Home.module.scss";

const Home = () => {
  return (
    <main className={style.containerMain}>
      <Hero />
      <MainFeatures />
    </main>
  );
};

export default Home;
