import React, { useState, useEffect, useCallback } from "react";
import Square from "./Square";
import Web3 from "web3";
import styles from "./Divedents.module.scss";
import AbiThorn from "../../AbiCactus";
import { BurgerButton } from "../BurgerButton/BurgerButton";
import BurgerMenu from "../Home/Hero/BurgerMenu";
import Logo from "../Header/Logo";

const Dividends = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [maxSupply, setMaxSupply] = useState("");
  const [totalTransactions, setTotalTransactions] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [frozenTokens, setFrozenTokens] = useState("");
  const [rewardPool, setRewardPool] = useState("");
  const [holderCount, setHolderCount] = useState("");
  const [transactionCount, setTransactionCount] = useState("");
  const [totalCommission, setTotalCommission] = useState("");
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [totalUnfrozen, setTotalUnfrozen] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [rewardResult, setRewardResult] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const contractAddress = "0xe1c5cd1efea20acf59b089ae2610d535f8ec589a";

  useEffect(() => {
    const provider = new Web3.providers.HttpProvider("https://polygon-rpc.com");
    const web3Instance = new Web3(provider);
    setWeb3(web3Instance);
    const contractInstance = new web3Instance.eth.Contract(AbiThorn, contractAddress);
    setContract(contractInstance);
  }, []);

  const claimRewards = useCallback(async () => {
    if (!isWalletConnected) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        setIsWalletConnected(true);
        claimRewards();
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else if (contract && userAddress) {
      try {
        await contract.methods.claimRewards().send({ from: userAddress });
        console.log("Rewards claimed successfully");
      } catch (error) {
        console.error("Error claiming rewards:", error);
      }
    }
  }, [contract, isWalletConnected, userAddress]);

  const fetchData = useCallback(async () => {
    if (contract) {
      const [
        maxSupplyResult,
        totalTransactionsResult,
        totalSupplyResult,
        initialSupplyResult,
        frozenTokensResult,
        rewardPoolResult,
        holderCountResult,
        transactionCountResult,
        totalCommissionResult,
        circulatingSupplyResult,
        totalUnfrozenResult
      ] = await Promise.all([
        contract.methods.MAX_SUPPLY().call(),
        contract.methods.totalTransactionAmount().call(),
        contract.methods.totalSupply().call(),
        contract.methods.INITIAL_SUPPLY().call(),
        contract.methods.frozenSupply().call(),
        contract.methods.rewardPool().call(),
        contract.methods.holderCount().call(),
        contract.methods.transactionCount().call(),
        contract.methods.totalCommission().call(),
        contract.methods.circulatingSupply().call(),
        contract.methods.totalUnfrozen().call()
      ]);

      setMaxSupply(formatNumber(maxSupplyResult, 18));
      setTotalTransactions(formatNumber(totalTransactionsResult, 18));
      setTotalSupply(formatNumber(totalSupplyResult, 18));
      setInitialSupply(formatNumber(initialSupplyResult, 18));
      setFrozenTokens(formatNumber(frozenTokensResult, 18));
      setRewardPool(formatNumber(rewardPoolResult, 18));
      setHolderCount(holderCountResult.toString());
      setTransactionCount(transactionCountResult.toString());
      setTotalCommission(formatNumber(totalCommissionResult, 18));
      setCirculatingSupply(formatNumber(circulatingSupplyResult, 18));
      setTotalUnfrozen(formatNumber(totalUnfrozenResult, 18));
    }
  }, [contract]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatNumber = (number, decimals) => {
    const formattedNumber = parseInt(number, 10) / Math.pow(10, decimals);
    return formattedNumber.toLocaleString();
  };

  const data = [
    { title: "Max Supply", value: maxSupply },
    { title: "Initial Supply", value: initialSupply },
    { title: "Total Supply", value: totalSupply },
    { title: "Circulating Supply", value: circulatingSupply },
    { title: "Frozen Supply", value: frozenTokens },
    { title: "Total Unfrozen", value: totalUnfrozen },
    { title: "Holder Count", value: holderCount },
    { title: "Transaction Count", value: transactionCount },
    { title: "Total Transaction ", value: totalTransactions },
    { title: "Total Commission", value: totalCommission },
    { title: "Reward Pool", value: rewardPool }
  ];

  const calculateReward = useCallback(async () => {
    if (contract && userAddress) {
      try {
        const reward = await contract.methods.calculateReward(userAddress).call();

        setRewardResult(formatNumber(reward.toString(), 18));
      } catch (error) {
        console.error("Error fetching reward:", error);
      }
    }
  }, [contract, userAddress]);

  const handleAddressChange = (event) => {
    setUserAddress(event.target.value);
  };

  const closeMenu = () => {
    setIsModelOpen(false);
    document.body.style.overflow = "auto";
  };
  return (
    <div className={styles.container}>
      <div className={styles.mobileHeader}>
        <Logo />

        <BurgerButton setIsModelOpen={setIsModelOpen} />
      </div>

      <div style={containerStyle}>
        {data.map((item, index) => (
          <Square key={index} title={item.title} value={item.value} />
        ))}
      </div>
      <div className={styles.buttons}>
        <div style={inputContainerStyle}>
          <input
            type="text"
            value={userAddress}
            onChange={handleAddressChange}
            placeholder="Enter user address"
            style={inputStyle}
          />
          <button onClick={calculateReward} className={styles.buttonStyle}>
            Calculate Reward
          </button>
          {rewardResult && <p className={styles.textCalculateReward}>Reward: {rewardResult}</p>}
        </div>
        {/* <div style={claimRewardsStyle}>
          <button onClick={claimRewards} className={styles.buttonStyle}>
            Claim Rewards
          </button>
        </div> */}
      </div>
      {isModelOpen && <BurgerMenu closeMenu={closeMenu} />}
    </div>
  );
};

const claimRewardsStyle = {
  margin: 30,
  width: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "rgba(92, 232, 215, 0.03)",
  borderRadius: "12px"
};

const containerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  padding: "10px"
};

const inputContainerStyle = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "rgba(92, 232, 215, 0.03)",
  borderRadius: "12px"
};

const inputStyle = {
  padding: "10px",
  marginBottom: "10px",
  fontSize: "16px",
  width: "90%",
  backgroundColor: "rgba(18, 12, 20, 0.3)",
  border: "1px solid",
  borderColor: "#34AD8A",
  borderRadius: "5px",
  color: "#FFFFFF"
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  cursor: "pointer"
};

export default Dividends;
