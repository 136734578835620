import React from "react";
import styles from "../../Docs.module.scss";
import { ResponsivePieCanvas } from "@nivo/pie";

const Tokenomics = () => {
  const data = [
    { id: "Frozen Pool", label: "Frozen Pool - 50%", value: 50 },
    { id: "Liquidity", label: "Liquidity - 12%", value: 12 },
    { id: "Storage Pool", label: "Storage Pool - 5%", value: 5 },
    { id: "Public Sale Round 1", label: "Public Sale Round 1 - 3%", value: 3 },
    { id: "Public Sale Round 2", label: "Public Sale Round 2 - 6%", value: 6 },
    {
      id: "Public Sale Round 3",
      label: "Public Sale Round 3 - 10%",
      value: 10
    },
    { id: "Team", label: "Team - 7%", value: 7 },
    { id: "Marketing", label: "Marketing - 5%", value: 5 },
    { id: "AIRDROP", label: "AIRDROP - 2%", value: 2 }
  ];
  const theme = {
    tooltip: {
      container: {
        background: "white",
        color: "black",
        fontSize: "13px"
      }
    }
  };

  return (
    <div style={{ overflowY: "auto", height: "90vh" }}>
      <p className={styles.text}>
        Thorn, developed on the Polygon blockchain, with a total supply of 100 million units. The
        token economy is designed to ensure sustainability and incentivize all ecosystem
        participants.
      </p>
      <div style={{ height: 500, width: 800 }}>
        <ResponsivePieCanvas
          data={data}
          margin={{ top: 10, right: 300, bottom: 40, left: 120 }} // Adjusted margin for layout
          innerRadius={0.6} // Adjusted inner radius for chart appearance
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "set3" }} // Set your color scheme
          borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#ffffff"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          theme={theme} // Applied theme for tooltip
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 160,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 30,
              itemHeight: 50,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle"
            }
          ]}
        />
      </div>
      <h3 className={styles.textH}>Token Distribution</h3>
      <p className={styles.textChapter}>Frozen Pool — 50 000 000 (50%)</p>
      <p className={styles.text}>
        This part of the tokens is frozen and will be gradually released during conducting
        transactions between addresses. With every transaction there will be unfreeze 0.1% of the
        transfer amount, which helps to increase activity on the network.
      </p>
      <h4 className={styles.textChapter}>Liquidity — 12 000 000 (12%)</h4>
      <p className={styles.text}>
        Funds aimed at maintaining the liquidity of the token on cryptocurrency exchanges and other
        platforms that provide convenience exchange and value stability.
      </p>
      <h5 className={styles.textChapter}>Storage Pool — 5 000 000 (5%)</h5>
      <p className={styles.text}>
        Reserve used to finance necessary upgrades infrastructure and ensuring reliable network
        operation.
      </p>
      <h6 className={styles.textChapter}>Public Sales — 19 000 000 - 19%</h6>
      <p className={styles.text}>Round 1 - 3 000 000 - 3% </p>
      <p className={styles.text}>Round 2 - 6 000 000 - 6% </p>
      <p className={styles.text}>Round 3 - 10 000 000 - 10% </p>
      <p className={styles.text}>
        These tokens are intended to be sold to a wide audience, which facilitates expanding the
        user base and attracting investments.
      </p>
      <h7 className={styles.textChapter}>Team — 7 000 000 (7%)</h7>
      <p className={styles.text}>
        Tokens designated to reward developers and team members, stimulating them to contribute to
        the long-term development of the project. Tokens will be be paid over 2 years, which
        provides long-term motivation and team involvement in the project.
      </p>
      <h8 className={styles.textChapter}>Marketing — 5 000 000 (5%)</h8>
      <p className={styles.text}>
        Funds intended for marketing campaigns, aimed at increasing the visibility of the project
        and attracting new users.
      </p>
      <h9 className={styles.textChapter}>Airdrop — 2 000 000 (2%)</h9>
      <p className={styles.text}>
        Tokens distributed free of charge among active community members and early users, to
        encourage participation and activity in ecosystem.
      </p>
    </div>
  );
};

export default Tokenomics;
